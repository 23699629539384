import { useEffect } from "react";
import "./App.css";
import Template from "./Template";
import logo from "./mobile-logo.svg";
import { Buffer } from "buffer";
import axios from "axios";

function App() {
  useEffect(() => {
    if (window.location.pathname && window.location.pathname.slice(1)) {
      const decodedRequestBodyString = Buffer.from(
        window.location.pathname.slice(1),
        "base64"
      );
      const requestBodyObject = JSON.parse(decodedRequestBodyString);

      displayRazorpay(requestBodyObject);
    }
  }, []);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.id = "razorpay-script";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(obj) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: obj.amount,
      currency: obj.currency,
      name: "Live History India",
      description: obj.description,
      image: { logo },
      order_id: obj.order_id,
      handler: async function (response) {
        var data = JSON.stringify({
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
        });

        var config = {
          method: "post",
          url: `${process.env.REACT_APP_PAYMENT_URL}/verify-order`,
          headers: {
            Authorization: `Bearer ${obj.token}`,
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_X_API_KEY,
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            let obj2 = {
              refreshToken: obj.refreshToken,
              href: obj.href,
            };
            let objJsonStr = JSON.stringify(obj2);
            let objJsonB64 = Buffer.from(objJsonStr).toString("base64");

            window.location.replace(
              `${process.env.REACT_APP_URL}/verify/${objJsonB64}`
            );
          })
          .catch(function (error) {
            console.log("error", error);
          });
      },
      prefill: {
        name: obj.name,
        email: obj.email,
        contact: obj.contact,
      },
      notes: {
        address: obj.address,
      },
      theme: {
        color: "#BFA682",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
      // alert(response.error.code);
      alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
    paymentObject.open();
  }

  return (
    <div className="App">
      <Template />
    </div>
  );
}

export default App;
